<template>
	<div class="settings-wrapper">
		<q-field
			v-if="contact_field.name == 'brand-phone'"
			stack-label
			borderless
			indicator-color="primary"
			active-color="primary"
			:label="contact_field.label"
			:error="hasError"
			:error-message="branding_errors[contact_field.value]"
		>
			<vue-tel-input
				v-model="value"
				v-bind="bindProps"
				class="full-width q-pa-sm q-mt-sm"
				:validCharactersOnly="true"
				:class="$q.screen.lt.sm ? 'small-view' : ''"
			>
			</vue-tel-input>
		</q-field>
		<q-input
			v-else
			v-model="value"
			outlined
			:label="contact_field.label"
			:id="contact_field.name"
			:ref="contact_field.name"
			:hint="subdomain"
			:error="hasError"
			:error-message="branding_errors[contact_field.value]"
		>
			<template v-slot:append v-if="contact_field.name == 'brand-login-url'">
				<q-btn
					flat
					icon="content_copy"
					@click.stop="copyUrl"
					class="cursor-pointer copy-btn"
				/>
			</template>
		</q-input>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '@/services/utils.js'

export default {
	name: 'ContactDataFields',
	props: {
		contact_field: Object,
	},
	data() {
		return {
			bindProps: {
				mode: 'international',
				defaultCountry: 'GB',
				placeholder: 'Enter a phone number',
			},
		}
	},
	computed: {
		...mapGetters(['custom_branding', 'branding_errors']),
		value: {
			get() {
				return this.$store.getters.custom_branding[this.contact_field.value]
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding[this.contact_field.value] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		subdomain_url() {
			if (this.custom_branding.brand_login_url != null)
				return `alertcascade.net/${this.custom_branding.brand_login_url}`
			return 'alertcascade.net/'
		},
		subdomain() {
			if (this.contact_field.name == 'brand-login-url' && this.subdomain_url) {
				return this.subdomain_url
			}
		},
		hasError() {
			return this.branding_errors[this.contact_field.value] ? true : false
		},
	},
	methods: {
		...mapActions(['updateCustomBranding']),
		copyUrl() {
			utils.copyToClipboard(this.subdomain_url) &&
				this.$refs[this.contact_field.name].select()
			this.$q.notify('The brand login url was copied successfully.')
		},
	},
}
</script>

<style>
.vue-tel-input input {
	background-color: #fafafa;
}

.vue-tel-input:focus-within {
	border-color: var(--primary-color);
	border-width: 2px;
	box-shadow: inset 0 0 0;
}

.small-view .vti__dropdown .vti__dropdown-list {
	width: auto !important;
}

.copy-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
