<template>
	<div class="main-container">
		<div id="print" style="max-width: 100%">
			<div class="me-card-container">
				<div class="helper">
					<div class="article-details">
						<div class="company-name">{{ company_name }}</div>
						<div class="phone">
							<i class="fal fa-phone noHover"></i>
							{{ custom_branding.brand_phone }}
						</div>
						<div class="contact-email">
							<i class="fal fa-envelope noHover"></i>
							{{ custom_branding.brand_email }}
						</div>
					</div>
					<div class="qr-container">
						<img alt="QR code" class="post-image" :src="mecard_qr" />
					</div>
				</div>
				<div class="helper-one">
					<div class="qr-container">
						<img alt="QR code" class="small-image" :src="subdomain_qr" />
					</div>
					<div class="subdomain">
						<i class="fal fa-link noHover"></i>
						<p class="login-url">{{ custom_branding.brand_login_url }}</p>
						<p class="login-url">{{ domain }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="column">
			<div class="row justify-end q-pt-sm device-btns-wrapper">
				<q-btn
					outline
					color="primary"
					class="q-mb-md col-12"
					icon-right="print"
					label="Print Card"
					@click="printDiv('print')"
				/>
				<q-btn
					outline
					color="primary col-12"
					icon-right="qr_code_scanner	"
					label="Generate QR"
					@click="generateQR"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import BrandingAPI from '@/services/api/Branding.js'
import CardStyles from '@/utils/print.js'
import utils from '@/services/utils.js'

export default {
	name: 'ContactCardPreview',
	data() {
		return {
			mecard_qr: '',
			subdomain_qr: '',
			domain: '.alertcascade.net/',
			company_name: '',
		}
	},
	computed: {
		...mapGetters(['current_CG', 'custom_branding']),
	},
	async created() {
		this.company_name = this.current_CG.customer_group.name
		this.mecard_qr = 'data:image/png;base64,' + (await this.generateMecardQR())
		this.subdomain_qr =
			'data:image/png;base64,' + (await this.generateSubdomainQR())
	},
	methods: {
		async generateMecardQR() {
			let mecard = {
				phone_number: this.custom_branding.brand_phone,
				name: this.company_name,
				email: this.custom_branding.brand_email,
			}
			return await BrandingAPI.generateQRMecard(mecard)
		},

		async generateSubdomainQR() {
			let subdomain_link = `${this.custom_branding.brand_login_url}${this.domain}`
			return await BrandingAPI.generateQRSubdomain({ link: subdomain_link })
		},

		async generateQR() {
			let mecardQR = await this.generateMecardQR()
			let subdomainQR = await this.generateSubdomainQR()
			utils.directDownloadFile(
				'Me_card',
				'png',
				`data:application/png;base64,${mecardQR}`
			)
			utils.directDownloadFile(
				'Subdomain',
				'png',
				`data:application/png;base64,${subdomainQR}`
			)
			if (!this.branding_errors) {
				this.$q.notify('The QR was created successfully.')
			} else {
				this.$q.notify({
					timeout: 0,
					message: this.branding_errors,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},

		printDiv(divName) {
			const printHtml = document.getElementById(divName).innerHTML
			let script =
				'<script src="https://kit.fontawesome.com/c374df1b50.js" crossorigin="anonymous"><\/script>'
			const WinPrint = window.open(
				'',
				'',
				'width=700,height=700,toolbar=0,scrollbars=0,status=0,popup=yes'
			)
			WinPrint.document.write(
				'<html><head>' + CardStyles.getCardStyles() + script + '</head>'
			)
			WinPrint.document.write(`<body>${printHtml}</body></html>`)
			setTimeout(() => {
				WinPrint.document.close()
			}, 400)
			setTimeout(() => {
				WinPrint.print(), WinPrint.close()
			}, 800)
		},
	},
}
</script>
<style scoped>
.noHover {
	pointer-events: none;
}
.subdomain {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}
.main-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: 5px;
}

.me-card-container {
	max-width: 100%;
	width: 351px;
	height: 195px;
	border-style: double;
	border-radius: 9px;
	border-color: var(--ac-grey-light);
	background-color: var(--card-background);
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
	color: var(--card-text);
}

.helper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.helper-one {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.article-details {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
}

.post-image {
	width: 80px;
	height: 80px;
	object-fit: cover;
}

.small-image {
	width: 60px;
	height: 60px;
	object-fit: cover;
}

.company-name {
	font-size: 18px;
	font-weight: bold;
	height: 30px;
	margin-bottom: 15px;
}

.phone {
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 5px;
}

.contact-email {
	font-size: 12px;
	line-height: 21px;
	margin-bottom: 5px;
}

.login-url {
	font-size: 12px;
	line-height: 21px;
	margin: 0;
}

.fa-link {
	padding-right: 5px;
	padding-left: 5px;
}

.qr-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-self: center;
}
</style>
