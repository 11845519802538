import store from '@/store/store.js';

export default {
	getCardStyles() {
		let card_background = store.getters.current_CG.customer_group.highlight_color ?
			store.getters.current_CG.customer_group.highlight_color : '#118f99'
		let text_color = getComputedStyle(document.documentElement).getPropertyValue('--card-text');
		let border_color = getComputedStyle(document.documentElement).getPropertyValue('--ac-grey-light');

		return `
			<style>
			*{
				font-family: Verdana, Geneva, sans-serif;
			}
			.noHover{
				pointer-events: none;
			}
			.subdomain{
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: wrap;
			}
			.main-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row;
				padding:5px;
			}

			.me-card-container {
				width: 351px;
				height: 195px;
				border-style: double;
				border-radius: 9px;
				border-color: ${border_color};
				background-color: ${card_background};
				margin: 5px;
				padding: 10px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: flex-start;
				color: ${text_color};
			}
			.helper{
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.helper-one{
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
			.article-details {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-direction: column;
			}

			.post-image {
				width: 80px;
				height: 80px;
				object-fit: cover;
			}
			.small-image{
				width: 60px;
				height: 60px;
				object-fit: cover;
			}
			.company-name{
				font-size:25px;
				height: 26px;
				margin-bottom: 10px;
			}
			.phone{
				font-size: 18px;
				line-height: 21px;
				margin-bottom: 10px;
			}
			.contact-email{
				font-size: 12px;
				line-height: 21px;
				margin-bottom: 10px;
			}
			.login-url {
				font-size: 12px;
				line-height: 21px;
				margin: 0;

			}
			.fa-link{
				padding-right: 5px;
				padding-left: 5px;
			}
			.qr-container{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-self: center;
			}
			</style>
		`;
	},
};







