<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">Contact</h1>
		<div v-for="contact_field in contact_data" :key="contact_field.name">
			<contact-data-fields :contact_field="contact_field" />
		</div>

		<div v-if="has_all_data">
			<q-expansion-item
				class="text-left"
				expand-separator
				icon="contact_emergency"
				label="Preview Card"
				caption="Preview printable"
			>
				<contact-card-preview />
			</q-expansion-item>
		</div>
	</div>
</template>

<script>
import ContactDataFields from '@/components/custom_branding/ContactDataFields.vue'
import ContactCardPreview from '@/components/custom_branding/ContactCardPreview.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'CustomBrandingContact',
	components: {
		ContactDataFields,
		ContactCardPreview,
	},
	data() {
		return {
			contact_data: [],
		}
	},
	computed: {
		...mapGetters(['custom_branding']),
		has_all_data() {
			return (
				this.custom_branding.brand_login_url &&
				this.custom_branding.brand_email &&
				this.custom_branding.brand_phone
			)
		},
	},
	created() {
		this.contact_data = [
			{
				label: 'Brand login url',
				name: 'brand-login-url',
				value: 'brand_login_url',
				icon: 'content_copy',
			},
			{
				label: 'Custom Email',
				name: 'brand-email',
				value: 'brand_email',
			},
			{
				label: 'Custom Phone',
				name: 'brand-phone',
				value: 'brand_phone',
			},
		]
	},
}
</script>
